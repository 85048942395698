import { fromJS, Map } from "immutable";

import logger from "Libs/logger";

const LOAD_THEME_START = "app/themes/load_theme_start";
const LOAD_THEME_SUCCESS = "app/themes/load_theme_success";
const LOAD_THEME_FAILURE = "app/themes/load_theme_failure";

let theme = {};

/*if (process.env.VENDOR_URL) {
  theme = process.env.VENDOR_THEME;
}*/

const themes = [
  {
    name: "default",
    brandColor: "#4786ff",
    background: "#f0f2f5",
    sectionBg: "#ffffff",
    text: "#1a192b",
    navBG: "#1a192b",
    navBar: "#302f45",
    navText: "#ffffff",
    navActive: "#4a495e",
    buttonBg: "#4786ff",
    buttonHover: "#1664FF",
    buttonText: "#ffffff",
    links: "#4786FF",
    error: "#fd716a",
    warning: "#e2ba23",
    status: "#55c1a8",
    setupSidebar: "#1a192b",
    setupSidebarActive: "#4a495e",
    setupSidebarActiveText: "#ffffff",
    inactive: "#98a0ab",
    screenshotBg: "#302f45",
    rootIcon: "#1fc79d",
    iconBg: "#4786ff",
    highlightBg: "#e7f0ff",
    menuHover: "rgba(134,181,255,0.2)"
  },
  {
    name: "contrast",
    brandColor: "#1664ff",
    background: "#f0f2f5",
    sectionBg: "#ffffff",
    text: "#1a192b",
    navBG: "#1a192b",
    navBar: "#01001a",
    navText: "#ffffff",
    navActive: "#4a495e",
    buttonBg: "#1664ff",
    buttonHover: "#0a5cff",
    buttonText: "#ffffff",
    links: "#1664ff",
    error: "#ed1c24",
    warning: "#e2ba23",
    status: "#55c1a8",
    setupSidebar: "#1a192b",
    setupSidebarActive: "#ffffff",
    setupSidebarActiveText: "#1a192b",
    inactive: "#4a495e",
    screenshotBg: "#302f45",
    rootIcon: "#1fc79d",
    iconBg: "#4786ff",
    highlightBg: "#e7f0ff",
    menuHover: "rgba(134,181,255,0.2)"
  },
  {
    name: "ezplatform",
    brandColor: "#f15a22",
    background: "#f7f7f7",
    sectionBg: "#ffffff",
    text: "#1a192b",
    navBG: "#3a3b3c",
    navBar: "#4c4c4c",
    navText: "#ffffff",
    navActive: "#262626",
    buttonBg: "#f15a22",
    buttonHover: "#cb4716",
    buttonText: "#ffffff",
    links: "#0094ce",
    error: "#ed1c24",
    warning: "#e2ba23",
    status: "#55c1a8",
    setupSidebar: "#3a3a3a",
    setupSidebarActive: "#ffffff",
    setupSidebarActiveText: "#3a3a3a",
    inactive: "#4a495e",
    screenshotBg: "#3a3b3c",
    rootIcon: "#6ed28a",
    iconBg: "#0094ce",
    highlightBg: "#c9eefb",
    menuHover: "#c9eefb"
  }
];

export const loadTheme = i => {
  return async dispatch => {
    dispatch({ type: LOAD_THEME_START });

    try {
      if (!process.env.VENDOR_URL) {
        /*const platformLib = await import("Libs/platform");
        const client = platformLib.default;

        const theme = await client.getThemes();*/
        theme = themes[i];
      }

      dispatch({
        type: LOAD_THEME_SUCCESS,
        payload: theme
      });
    } catch (err) {
      logger(err, {
        action: "loadTheme"
      });
      dispatch({ type: LOAD_THEME_FAILURE, error: true, payload: err });
    }
  };
};

export default function themeReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_THEME_START:
      return state.set("loading", true);
    case LOAD_THEME_SUCCESS:
      return state
        .set("loading", false)
        .set("data", fromJS(action.payload))
        .set("errors", false);
    case LOAD_THEME_FAILURE:
      return state.set("loading", false).set("errors", action.payload);
    default:
      return state;
  }
}
